import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getBuildingNameService,
  getFlatNameService,
} from "../services/assetServices";

const initialState = {
  isLoading: false,
  isSuccess: false,
  buildingName: [],
  flatName: [],
};

export const fetchBuildingName = createAsyncThunk(
  "buildingNameSlice",
  async () => {
    try {
      const resp = await getBuildingNameService();
      return resp.data.data.rows;
    } catch (error) {}
  }
);
export const fetchUnitName = createAsyncThunk("flatNameSlice", async (id) => {
  try {
    const resp = await getFlatNameService(id);
    return resp.data.data;
  } catch (error) {}
});

const MultiUseSlice = createSlice({
  name: "multiUse",
  initialState,
  reducers: {},

  extraReducers: {
    [fetchBuildingName.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchBuildingName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.buildingName = action.payload;
    },
    [fetchBuildingName.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [fetchUnitName.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchUnitName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.flatName = action.payload;
    },
    [fetchUnitName.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default MultiUseSlice.reducer;
