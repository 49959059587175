import { axiosClient } from "./axiosClient";

export const createCategoryApi = async (payload) => {
  const resp = await axiosClient.post(
    `/maintenances/admin/categories`,
    payload
  );
  return resp;
};

export const fetchCategoryService = async ({ page, limit, searchText }) => {
  const resp = await axiosClient.get(
    `/maintenances/admin/categories?page=${page}&limit=${limit}&search=${searchText}`
  );
  return resp;
};
export const fetchCategoryDetailService = async (id) => {
  const resp = await axiosClient.get(`/maintenances/admin/categories/${id}`);
  return resp;
};

export const visibilityCategoryService = async (categoryId) => {
  const resp = await axiosClient.patch(
    `/maintenances/admin/categories/visibility/${categoryId}`
  );
  return resp;
};

export const updateCategoryService = async ({ data1, categoryId }) => {
  const resp = await axiosClient.patch(
    `/maintenances/admin/categories/${categoryId}`,
    data1
  );
  return resp;
};

export const deleteCategoryService = async (id) => {
  const resp = await axiosClient.delete(`/maintenances/admin/categories/${id}`);
  return resp;
};
