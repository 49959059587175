import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { TiDeleteOutline } from "react-icons/ti";
import csvimage from "../../../assets/csvimage.png";
import DocImage from "../../../assets/DocImage.png";
import pdficon from "../../../assets/pdficon.jpg";
import "./index.css";

import { MenuItem, TextareaAutosize, TextField } from "@mui/material";
import { toast } from "react-toastify";
import UplaodForUpdate from "../../../components/UplaodForUpdate";
import { uploadFile } from "../../../services/allPropertiesAPI";
import {
  getMantinanceType,
  updateServisesRequest,
} from "../../../services/allServisesApi";
import "./index.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="growDailoc">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditDetaildailog2({
  editdetailOpenTwo,
  generatedBy,
  EditDetailsCloseTwo,
  description,
  images,
  type,
  id,
  fetchAllRequestProperty,
  setAllRequestProperty,
  AllRequestProperty,
  subCategory,
}) {
  const [handlePropertyfiled, setHandlePropertyfiled] = useState({
    description: "",
    files: [],
    type: {
      key: "",
      type: "",
    },
    subCategory: {
      key: "",
      type: "",
    },
  });

  useEffect(() => {
    setHandlePropertyfiled({
      description: description,
      files: images,
      type: type,
      subCategory: subCategory,
    });
  }, [description, images]);
  const [mantinanceType, setMantinanceType] = useState([]);
  const [maintenanceSubType, setMaintenanceSubType] = useState([]);

  const fetchMantinaceType = async () => {
    const resp = await getMantinanceType();
    setMantinanceType(resp?.data?.data?.rows);
    const categories = resp?.data?.data?.rows.filter(
      (hhh) => hhh.isVisible !== true
    );
    setMaintenanceSubType(categories);
  };
  useEffect(() => {
    fetchMantinaceType();
  }, []);

  useEffect(() => {
    setHandlePropertyfiled((prevState) => {
      const filterTypeKey = mantinanceType?.filter((type) =>
        console.log(type, "")
      )?.[0];

      return {
        ...prevState,
        type: {
          key: filterTypeKey?.key,
          type: filterTypeKey?.type,
        },
      };
    });
  }, [type, mantinanceType]);

  useEffect(() => {
    setHandlePropertyfiled((prevState) => {
      const filterTypeKey = maintenanceSubType?.filter((type) =>
        console.log(type, "")
      )?.[0];

      return {
        ...prevState,
        subCategory: {
          key: filterTypeKey?.key,
          type: filterTypeKey?.type,
        },
      };
    });
  }, [subCategory, maintenanceSubType]);

  const flatId = AllRequestProperty?.flatId;

  const getUpdateServisesRequest = async () => {
    try {
      const requestId = id;
      const data = {
        ...(generatedBy == "Admin" && { flatId: flatId }),
        categoryId: handlePropertyfiled?.type.type,
        subCategoryId: handlePropertyfiled?.subCategory?.type,
        description: handlePropertyfiled?.description,
        files: handlePropertyfiled.files,
      };
      const resp = await updateServisesRequest(requestId, { data });

      if (resp.data.status == "success") {
        fetchAllRequestProperty();
        toast.success(resp.data.data);
      } else {
        toast.error(resp.data.msg);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const removeImage = (i) => {
    const remove = handlePropertyfiled?.files.filter(
      (ele, index) => index !== i
    );
    setHandlePropertyfiled({ ...handlePropertyfiled, files: remove });
  };
  const hanldeUpadteImage = async (e) => {
    const fileListArray = e.target.files;

    const formData = new FormData();

    for (let i = 0; i < fileListArray?.length; i++) {
      formData.append("file", fileListArray[i]);
    }
    const response = await uploadFile(formData);

    let nameAndLocaiton = response?.data?.data?.map((ele) => {
      return {
        url: ele?.location,
        name: ele?.originalName,
        contentType: ele.contentType,
      };
    });

    let newhanle = handlePropertyfiled.files;
    newhanle.push(nameAndLocaiton[0]);

    setHandlePropertyfiled({ ...handlePropertyfiled, files: newhanle });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={EditDetailsCloseTwo}
        aria-labelledby="customized-dialog-title"
        open={editdetailOpenTwo}
        className="details-dialog"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={EditDetailsCloseTwo}
        >
          {generatedBy && (
            <>
              <div>
                <h5 className="mb-3">Update Request Details</h5>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    select
                    label="Admin Category"
                    inputProps={{ maxLength: 100 }}
                    className="inputopacity"
                    multiline
                    maxRows={4}
                    maxLength={10}
                    value={handlePropertyfiled.type.key || type}
                    onChange={(e) =>
                      setHandlePropertyfiled({
                        ...handlePropertyfiled,
                        type: { ...e.target.value },
                      })
                    }
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "450px",
                      lineHeight: "19px",
                      marginRight: "75px",
                    }}
                  >
                    {mantinanceType?.map((option) => {
                      return (
                        <MenuItem
                          key={option.key}
                          value={{
                            key: option.name_en,
                            type: option.id,
                          }}
                        >
                          {option.name_en}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="mt-3">
                  <TextField
                    id="standard-multiline-flexible"
                    select
                    label="User Category"
                    inputProps={{ maxLength: 100 }}
                    className="inputopacity"
                    multiline
                    maxRows={4}
                    maxLength={10}
                    value={handlePropertyfiled?.subCategory?.key || subCategory}
                    onChange={(e) =>
                      setHandlePropertyfiled({
                        ...handlePropertyfiled,
                        subCategory: { ...e.target.value },
                      })
                    }
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "450px",
                      lineHeight: "19px",
                      marginRight: "75px",
                    }}
                  >
                    {maintenanceSubType?.map((option) => {
                      return (
                        <MenuItem
                          key={option.key}
                          value={{
                            key: option?.name_en,
                            type: option?.id,
                          }}
                        >
                          {option.name_en}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div>
                  <div className="mt-3">
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      placeholder="Add Description"
                      value={handlePropertyfiled.description}
                      onChange={(e) =>
                        setHandlePropertyfiled({
                          ...handlePropertyfiled,
                          description: e.target.value,
                        })
                      }
                      style={{
                        width: "85%",
                        height: "100px",
                        padding: "5px",
                        backgroundColor: "#FCFCFC",
                        borderRadius: " 1rem",
                        padding: "10px",
                        borderStyle: "none",
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="mb-2">
                    <input
                      type="file"
                      multiple
                      id="uploadUser"
                      style={{ display: "none" }}
                      onChange={hanldeUpadteImage}
                    />
                    <label htmlFor="uploadUser">
                      <UplaodForUpdate />
                    </label>
                  </div>

                  <div className="d-flex">
                    {handlePropertyfiled?.files &&
                      handlePropertyfiled?.files.map((docData, i) => {
                        if (docData.contentType == "application/pdf") {
                          return (
                            <>
                              <img
                                src={pdficon}
                                alt="preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span
                                style={{
                                  position: "relative",
                                  left: "-15px",
                                  top: "-16px",
                                }}
                                onClick={() => {
                                  removeImage(i);
                                }}
                              >
                                {" "}
                                <TiDeleteOutline
                                  size={20}
                                  style={{
                                    background: "rgb(12, 52, 78)",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                />
                              </span>
                            </>
                          );
                        } else if (docData.contentType == "application/x-msi") {
                          return (
                            <>
                              <img
                                src={DocImage}
                                alt="preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span
                                style={{
                                  position: "relative",
                                  left: "-15px",
                                  top: "-16px",
                                }}
                                onClick={() => {
                                  removeImage(i);
                                }}
                              >
                                {" "}
                                <TiDeleteOutline
                                  size={20}
                                  style={{
                                    background: "rgb(12, 52, 78)",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                />
                              </span>
                            </>
                          );
                        } else if (
                          docData.contentType == "application/octet-stream"
                        ) {
                          return (
                            <>
                              <img
                                src={csvimage}
                                alt="preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span
                                style={{
                                  position: "relative",
                                  left: "-15px",
                                  top: "-16px",
                                }}
                                onClick={() => {
                                  removeImage(i);
                                }}
                              >
                                {" "}
                                <TiDeleteOutline
                                  size={20}
                                  style={{
                                    background: "rgb(12, 52, 78)",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                />
                              </span>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <img
                                src={docData.url}
                                alt="preview"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                              />
                              <span
                                style={{
                                  position: "relative",
                                  left: "-15px",
                                  top: "-16px",
                                }}
                                onClick={() => {
                                  removeImage(i);
                                }}
                              >
                                {" "}
                                <TiDeleteOutline
                                  size={20}
                                  style={{
                                    background: "rgb(12, 52, 78)",
                                    borderRadius: "10px",
                                    color: "white",
                                  }}
                                />
                              </span>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>

                <button
                  style={{
                    background: "#0C344E",
                    border: "none",
                    borderRadius: "5px ",
                    marginTop: "10px",
                    color: "white",
                    fontWeight: "500px !important",
                    fontSize: "16px",
                    padding: "8px 30px",
                  }}
                  onClick={() => {
                    fetchAllRequestProperty();
                    getUpdateServisesRequest();
                    EditDetailsCloseTwo();
                  }}
                >
                  Update Request
                </button>
              </div>
            </>
          )}
        </BootstrapDialogTitle>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
