import { axiosClient, BASE_URL } from "./axiosClient";

export const getAllAssetData = async (data) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/assets?page=${data.page}&limit=${data.postPerPage}&buildingId=${data.selectedBuilding}&search=${data.searchText}`
  );
  return resp;
};
export const getAssetData = async (assetId) => {
  const resp = await axiosClient.get(`${BASE_URL}/assets/${assetId}`);
  return resp;
};

export const createAsset = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/assets`, data);
  return resp;
};

export const editAsset = async ({ data, assetId }) => {
  const resp = await axiosClient.patch(`${BASE_URL}/assets/${assetId}`, data);
  return resp;
};

export const deleteAsset = async (assetId) => {
  const resp = await axiosClient.delete(`${BASE_URL}/assets/${assetId}`);
  return resp;
};

export const getBuildingNameService = async () => {
  const resp = await axiosClient.get(`${BASE_URL}/buildings/admin/all`);
  return resp;
};

export const getFlatNameService = async (id) => {
  const resp = await axiosClient.get(`${BASE_URL}/flats/admin/all/&flat=${id}`);
  return resp;
};
