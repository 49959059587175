import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { getAllBuilding } from "../services/allPropertiesAPI";

const BuildingMultiSelectDropDown = ({
  label,
  name,
  setValue,
  width,
  innerWidth,
  buildings,
  disabled,
}) => {
  const [searchText, setSearchText] = useState("");
  const [buildingDataList, setBuildingDataList] = useState([]);
  const fetchBuildingDataList = async () => {
    const resp = await getAllBuilding();
    setBuildingDataList(resp.data.data.rows);
  };
  useEffect(() => {
    fetchBuildingDataList();
  }, []);

  const handleInputChangeBuilding = (event) => {
    const value = event.target.value;

    let elementExists = false;
    for (let i = 0; i < buildings.length; i++) {
      if (
        buildings[i].id === value.id ||
        buildings[i].buildingId === value.id
      ) {
        elementExists = true;
        break;
      }
    }
    if (!elementExists) {
      setValue(`${name}`, [...buildings, value]);
    }
  };
  return (
    <div style={{ marginTop: "25px" }}>
      <FormControl fullWidth>
        <InputLabel id="search-select-label" sx={{ marginLeft: "-14px" }}>
          {label}
        </InputLabel>
        <Select
          MenuProps={{ autoFocus: false }}
          sx={{ width: width }}
          labelId="search-select-label"
          id="search-select"
          variant="standard"
          value="Select Building"
          disabled={disabled}
          onChange={handleInputChangeBuilding}
          //   value={selectedBuilding ? selectedBuilding : buildingId}
          //   {...register(name)}
          name={name}
          label={label}
          onClose={() => setSearchText("")}
        >
          <ListSubheader>
            <TextField
              size="small"
              variant="standard"
              placeholder="Type to search..."
              sx={{
                width: innerWidth,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="Select Building">Select Property</MenuItem>
          {buildingDataList
            .filter((option) =>
              option?.name_en?.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((option, i) => (
              <MenuItem key={option?.id} value={option}>
                {option.name_en}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(BuildingMultiSelectDropDown);

{
  /* <Box>
  <TextField
    select
    label="Building"
    inputProps={{ maxLength: 100 }}
    multiline
    maxRows={4}
    maxLength={10}
    value="Select Building"
    onChange={handleInputChangeBuilding}
    name="buildings"
    variant="standard"
    style={{
      width: "300px",
      marginTop: "13px",
    }}
  >
    <MenuItem value="Select Building">Select Building</MenuItem>
    {buildingData?.map((option) => {
      return (
        <MenuItem key={option.id} value={option}>
          {option.name_en}
        </MenuItem>
      );
    })}
  </TextField>
</Box>; */
}
