import { Box, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNoticeData } from "../Redux/NoticeSlice";
import Loader from "../components/Loader";
import NoticesTable from "../components/NoticesTable";
import SearchBar from "../components/SearchBar";
import { buildingContext } from "../login/AuthProvider";

const Notices = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [postPerpage, setpostPerpage] = useState(10);
  const [showTotalData, setShowTotalData] = useState("");
  const { selectedBuilding } = React.useContext(buildingContext);

  useEffect(() => {
    dispatch(
      getNoticeData({ page, postPerpage, searchText, selectedBuilding })
    );
  }, [page, selectedBuilding]);

  const { noticeData, isLoading } = useSelector((state) => state.NoticesData);

  const totalCount = useMemo(() => {
    const newTotalCount = Math.ceil(noticeData?.count / postPerpage);
    setShowTotalData(noticeData?.count);
    return newTotalCount;
  }, [postPerpage, noticeData?.count]);

  const onSearchChange = (value) => {
    setSearchText(value);
    if (searchText) {
      dispatch(
        getNoticeData({ page, postPerpage, searchText, selectedBuilding })
      );
    }
  };

  return (
    <div className="top-level-container">
      <div className="home">
        {isLoading && <Loader />}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box className="property">
            <Box justifyContent="space-between" display="flex" width="74vw">
              <Box>
                <h4>Notices</h4>
              </Box>
              <Box display="flex">
                <SearchBar
                  onChange={onSearchChange}
                  placeholder="Search"
                  maxLength="10"
                />
                <Link to="/addnotices" style={{ textDecoration: "none" }}>
                  <Button
                    className="Button"
                    variant="contained"
                    color="success"
                    style={{
                      border: "1px solid #0C344E",
                      color: "#0C344E",
                      background: "white",
                      width: "187px",
                      height: "48px",
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontweight: "500",
                      fontSize: "16px",
                      lineHeight: "19px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5V19"
                        stroke="#0C344E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="#0C344E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Add Notices
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </div>
        <Box marginTop="50px">
          <NoticesTable
            {...{
              totalCount,
              setPage,
              page,
              showTotalData,
              setShowTotalData,
              postPerpage,
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default Notices;
