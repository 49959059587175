import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Box } from "@mui/material";
import { formatAMPM } from "../../../util";
import "./index.css";
import { MdDownloadForOffline } from "react-icons/md";
import statuspdf from "../../../assets/statuspdf.png";
import pdf from "../../../assets/pdf.png";
import DocImage from "../../../assets/DocImage.png";
import csvimage from "../../../assets/csvimage.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="growDailoc">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StatuslogViewDetailDailoc({
  viewDetailOpen,
  ViewhandleClose,
  AllRequestProperty,
  images,
  url,
}) {
  
  return (
    <div>
      <BootstrapDialog
        onClose={ViewhandleClose}
        aria-labelledby="customized-dialog-title"
        open={viewDetailOpen}
        className="details-dialog"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={ViewhandleClose}
        >
          <Grid my={3}>
            <h5>Status Change Logs</h5>
            {AllRequestProperty?.statusDetails?.map((curElem, index) => (
              <div
                className="d-card mb-3"
                style={{
                  width: "50vw",
                  padding: "1rem",
                  height: "fit-content",
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <p style={{ fontSize: "18px" }}>
                    
                    {
                      typeof AllRequestProperty?.statusDetails[index + 1]?.status!=="undefined" ?
                      `Status Changed ${
                        AllRequestProperty?.statusDetails &&
                        typeof AllRequestProperty?.statusDetails[index + 1]?.status !==
                          "undefined"
                          ? "from"
                          : ""
                      } ${
                        AllRequestProperty?.statusDetails &&
                        AllRequestProperty?.statusDetails[index + 1]?.status == "Pending" ? "open":AllRequestProperty?.statusDetails[index + 1]?.status
                      } to  `
                      :
                      `Request is currently in ${ AllRequestProperty?.statusDetails[index]?.status == "Pending" && "open"} State`
                    }
                      &nbsp;
                    {curElem.status === "Pending" && AllRequestProperty?.statusDetails?.length-1 !==index && (
                      <span className="Pending">
                        open
                      </span>
                    )}
                    {curElem.status === "Assigned" && (
                      <span className="Assigned">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "Re-Assigned" && (
                      <span className="Re-Assigned">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "In-Process" && (
                      <span className="In-Process">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "Completed" && (
                      <span className="Completed">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "Cancelled" && (
                      <span className="Cancelled">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "Re-open" && (
                      <span className="Cancelled">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "On Hold" && (
                      <span className="Cancelled">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                    {curElem.status === "Rejected" && (
                      <span className="Cancelled">
                        {curElem?.status && curElem?.status}
                      </span>
                    )}
                  </p>
                    

                  <p style={{ fontSize: "13px" }}>
                    {formatAMPM(curElem?.createdAt)}, &nbsp;
                    {curElem?.createdAt.slice(0, 10)}
                  </p>
                </Box>
                <div className="d-flex justify-content-between">
                  <div>
                    {curElem?.comment && (
                      <div>
                        <p style={{ color: "#727272", fontSize: "16px" }}>
                          Comment
                        </p>
                        <p style={{ fontSize: "16px" }}>{curElem?.comment}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    
                      {curElem?.files && curElem?.files?.map((docData) => 
                      
                         {
                        
                          if (docData.contentType == "application/pdf") {
                            return (
                              <>
                                <a href={docData.location} target="_blank">
                                  <img src={pdf}  style={{width:"25px",height:"25px"}}/>
                                </a>
                              </>
                            );
                          } else if (docData.contentType == "application/x-msi") {
                            return (
                              <>
                                <a href={docData.location} target="_blank">
                                  <img src={DocImage} style={{width:"25px",height:"25px"}} />
                                </a>
                              </>
                            );
                          } else if (docData.contentType == "application/octet-stream") {
                            return (
                              <>
                                <a href={docData.location} target="_blank">
                                  <img src={csvimage} style={{width:"25px",height:"25px"}} />
                                </a>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <a href={docData.url} target="_blank">
                                  <img src={docData.location} style={{width:"25px",height:"25px"}} />
                                </a>
                              </>
                            );
                          }
                          
                          
                                  }
                        
                      )}
                    
                  </div>
                </div>
              </div>
            ))}
          </Grid>
        </BootstrapDialogTitle>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
