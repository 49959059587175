import { axiosClient, BASE_URL } from "./axiosClient";

export const getCategoriesService = async () => {
  const resp = await axiosClient.get(`${BASE_URL}/ppm/categories`);
  return resp;
};

export const getAllAssetNameService = async (data) => {
  if (data?.building) {
    const resp = await axiosClient.get(
      `${BASE_URL}/assets/all?buildingId=${
        data?.building ? data?.building : ""
      }`
    );
    return resp;
  } else {
    const resp = await axiosClient.get(
      `${BASE_URL}/assets/all?&flatId=${data?.flat ? data?.flat : ""}`
    );
    return resp;
  }
};

export const addPreventativeService = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/ppm/`, data);
  return resp;
};

export const getPreventativeListingService = async (data) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/ppm?page=${data.page}&limit=${data.postPerPage}&buildingId=${data.selectedBuilding}&search=${data.searchText}`
  );
  return resp;
};
export const getPreventativeDetailService = async (id) => {
  const resp = await axiosClient.get(`${BASE_URL}/ppm/${id}`);
  return resp;
};

export const editPreventativeDetailService = async (data) => {
  const resp = await axiosClient.patch(`${BASE_URL}/ppm/${data.id}`, data);
  return resp;
};
