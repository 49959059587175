import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";

function createData(
  Total,
  Jan,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  July,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec
) {
  return {
    Total,
    Jan,
    Feb,
    Mar,
    Apr,
    May,
    Jun,
    July,
    Aug,
    Sep,
    Oct,
    Nov,
    Dec,
  };
}

const rows = [
  createData(
    "Income (AED)",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300"
  ),
  createData(
    "Expense (AED)",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300",
    "12300"
  ),
  createData(
    "Net Income (AED)",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100",
    "11100"
  ),
];

export default function UserRoleTable({ placeholder }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead className="thead">
            <TableRow>
              <TableCell align="center" className="bold">
                Name
              </TableCell>
              <TableCell align="center" className="bold">
                Type
              </TableCell>
              <TableCell align="center" className="bold">
                Description
              </TableCell>
              <TableCell align="center" className="bold">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">{row.Total}</TableCell>
                <TableCell align="center">{row.Jan}</TableCell>

                <TableCell align="center">{row.Feb}</TableCell>

                <TableCell align="center" className="makespace">
                  {true ? (
                    <span className="InactiveBc">InActive</span>
                  ) : (
                    <span className="activeBc">Active</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ margin: "10px", float: "right" }}
        count={10}
        shape="rounded"
      />
    </>
  );
}
