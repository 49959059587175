import { axiosClient } from "./axiosClient";

export const createHelplineApi = async ({ payload }) => {
  const resp = await axiosClient.post(`/helplines`, payload);
  return resp;
};

export const fetchHelplineData = async (data) => {
  const resp = await axiosClient.get(
    `/helplines/admin?page=${data.page}&limit=${data.postPerPage}&search=${data.searchText}&buildingId=${data.selectedBuilding}`
  );
  return resp;
};

export const fetchParticularHelplineDetail = async (Id) => {
  const resp = await axiosClient.get(`/helplines/${Id}`);
  return resp;
};
export const updateHelplineService = async ({ payload, helplineId }) => {
  const resp = await axiosClient.patch(`/helplines/${helplineId}`, payload);
  return resp;
};

export const iconsListApi = async (data) => {
  const resp = await axiosClient.get(`/images?search=${data}`);
  return resp;
};

export const getSoftDeleteHelpline = (helplineId) => {
  const resp = axiosClient.delete(`/helplines/${helplineId}`);
  return resp;
};
