import React from 'react'
import Image from "../../StaffDetail/assets/gallery.png"
import Textfiled from '../../components/Textfiled'

const EditLease = () => {
  return (
    <div>
        <div className='d-flex justify-content-between'>
            <h5>Edit Lease Details</h5>
            <p>Save Changes</p>
        </div>
        <div>
            <img src={Image} style={{width:"150px",height:"150px"}}/>
        </div>
        <div className='mt-2'>
            <h5>Other Details</h5>
        </div>
        <div className='d-flex justify-content-between'>
            <div><Textfiled /></div>
            <div><Textfiled /></div>
           
        </div>
    </div>
  )
}

export default EditLease