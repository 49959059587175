import { update } from "@react-spring/web";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCategoryApi,
  deleteCategoryService,
  fetchCategoryService,
  updateCategoryService,
  visibilityCategoryService,
} from "../services/categoryService";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isSuccessEdit: false,
  isDelete: false,
  categoryList: [],
};

export const createCategory = createAsyncThunk("/category", async (data) => {
  try {
    const { navigate, payload } = data;
    const resp = await createCategoryApi(payload);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Category create successfully");
      navigate("/category");
    }
  } catch (error) {}
});

export const getCategoryData = createAsyncThunk(
  "/get/category",
  async (data) => {
    try {
      const resp = await fetchCategoryService(data);
      return resp.data.data;
    } catch (error) {}
  }
);

export const updateCategory = createAsyncThunk(
  "/edit/category",
  async (payload) => {
    try {
      const { categoryId, data1 } = payload;
      const resp = await updateCategoryService({ data1, categoryId });
      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Category update successfully");
      }
    } catch (error) {}
  }
);

export const markedCategory = createAsyncThunk(
  "/mark/category",
  async (categoryId) => {
    try {
      const resp = await visibilityCategoryService(categoryId);

      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success(resp.data.data);
      }
    } catch (error) {}
  }
);

export const deleteCategory = createAsyncThunk(
  "/delete/category",
  async (id) => {
    try {
      const resp = await deleteCategoryService(id);
      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Category delete successfully");
      }
    } catch (error) {}
  }
);

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {},

  extraReducers: {
    [createCategory.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getCategoryData.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getCategoryData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.categoryList = action.payload;
    },
    [getCategoryData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [updateCategory.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccessEdit = false;
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccessEdit = true;
    },
    [updateCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccessEdit = false;
    },
    [markedCategory.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccessEdit = false;
    },
    [markedCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccessEdit = true;
    },
    [markedCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccessEdit = false;
    },
    [deleteCategory.pending]: (state, action) => {
      state.isLoading = true;
      state.isDelete = false;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isDelete = true;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.isDelete = false;
    },
  },
});

export default CategorySlice.reducer;
