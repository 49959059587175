import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import tick from "../../../assets/tick.gif";
import { getPaymentRequest } from "../../../services/allServisesApi";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ open, handleClose, requestID }) {
  const [getPayment, setgetPayment] = useState({});
  const fetchPaymentRequestData = async () => {
    const resp = await getPaymentRequest(requestID);
    setgetPayment(resp.data.data);
  };
  useEffect(() => {
    fetchPaymentRequestData();
  }, []);

  return (
    <div className="dailogbox">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="tickright">
          <img src={tick} alt="tick" width="20%" className="tick" />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <h6 className="psent">Payment Request Sent</h6>
            <h6 className="invoice">Invoice No. 000123456</h6>
          </BootstrapDialogTitle>
        </div>

        <DialogContent dividers>
          <div className="d-flex justify-content-center devider ">
            <div>
              <p>Property</p>
              <p>Units</p>
              <p>Resident</p>
              <p>Amount(ABD)</p>
            </div>
            <div>
              <p>{getPayment?.request?.flat?.building?.name_en}</p>
              <p>{getPayment?.request?.flat?.name_en}</p>
              <p>{getPayment?.request?.user?.name}</p>
              <p>{getPayment.totalAmount}</p>
            </div>
          </div>
        </DialogContent>
        {/* <div className="d-flex justify-content-center align-items-center viewp">
                    <a href="/eeee" className="view">
                        View Notices
                    </a>
                </div> */}
      </BootstrapDialog>
    </div>
  );
}
