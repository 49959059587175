import ColorTabs from "./ColorTabs";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Grid } from "@mui/material";
import PieChart from "./PieChart";
import { GetDashboardVisitorData } from "../services/DashboardApi";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import BarChartComp from "./BarChartComp";
import DateFilter from "./DateFilter";
import moment from "moment";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import { useContext } from "react";
import { buildingContext } from "../login/AuthProvider";

function VisitorStats() {
  const { selectedBuilding } = useContext(buildingContext);
  const [handleVisitorData, setHandleVisitorData] = useState({});

  var today = new Date();
  const [state, setState] = useState([
    {
      startDate: new Date(new Date().setDate(today.getDate() - 30)),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // state created to hold the first month that calendar is showing
  const [shownDateChangeValue, setShownDateChangeValue] = useState(new Date());

  // state created to check if use created next Month ou previous month
  const [isNextMonth, setIsNextMonth] = useState(true);
  const [handleData, setHandleData] = useState({});

  let formatStartDate = moment(state[0]?.startDate).format("DD-MM-YYYY");
  let formatEndDate = moment(state[0]?.endDate).format("DD-MM-YYYY");

  const fetchData = async () => {
    try {
      const response = await GetDashboardVisitorData(
        formatStartDate,
        formatEndDate,
        selectedBuilding
      );
      if (response?.data?.status) {
        setHandleVisitorData(response.data.data);
      } else {
        toast(response.data.status);
      }
    } catch {
      toast("err");
    }
  };

  useEffect(() => {
    fetchData();
  }, [state[0]?.endDate, selectedBuilding]);

  return (
    <>
      <div className="top-level-container">
        <div className="home" style={{ borderTopLeftRadius: "50px" }}>
          <Grid display="flex" justifyContent="space-between">
            <Grid sx={{display:"flex",width:"600px",flexWrap:"wrap",columnGap:"10px",rowGap:"10px"}}>
              <ColorTabs
                status=" Visitor Traffic"
                color="#D65400"
                count={handleVisitorData?.totalVisitorTraffic}
                icons=<DepartureBoardIcon />
              />
              <ColorTabs
                status=" Unique Traffic"
                color="#D65400"
                count={handleVisitorData?.totalUniqueVisitors}
                icons=<AccountCircleIcon />
              />
              <ColorTabs
                status="Today Checkins"
                color="#D65400"
                count={handleVisitorData?.todayCheckins}
                icons=<AccountCircleIcon />
              />
              <ColorTabs
                status="Active Visitor"
                color="#D65400"
                count={handleVisitorData?.activeVisitors}
                icons=<AccountCircleIcon />
              />
              <ColorTabs
                status="Checked Outs"
                color="#D65400"
                count={handleVisitorData?.checkedOutVisitors}
                icons=<AccountCircleIcon />
              />
              <ColorTabs
                status="Total Deliveries"
                color="#D65400"
                count={handleVisitorData?.totalDeliveries}
                icons=<AccountCircleIcon />
              />
            </Grid>
            <Grid>
              <DateFilter
                state={state}
                setState={setState}
                shownDateChangeValue={shownDateChangeValue}
                setShownDateChangeValue={setShownDateChangeValue}
                setIsNextMonth={setIsNextMonth}
                formatStartDate={formatStartDate}
                formatEndDate={formatEndDate}
              />
            </Grid>
          </Grid>
          <Grid display="flex" gap={4}>
            <Grid width="45%" my={4}>
              <BarChartComp handleVisitorData={handleVisitorData} />
            </Grid>

            <Grid width="45%" my={4}>
              <PieChart handleVisitorData={handleVisitorData} />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default VisitorStats;
