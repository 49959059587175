import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import UserRoleTable from "./UserRoleTable";

const UserRole = () => {
  return (
    <Box className="top-level-container">
      <Box className="home">
        <Box display="flex" justifyContent="space-between" width="74vw">
          <Box>
            <h4>User Role</h4>
          </Box>
          <Box display="flex">
            <SearchBar
              //   onChange={onSearchChange}
              placeholder="Search"
              maxLength="10"
            />
            <Link to="/add-userrole" style={{ textDecoration: "none" }}>
              <Button
                className="Button"
                variant="contained"
                color="success"
                style={{
                  border: "1px solid #0C344E",
                  color: "#0C344E",
                  background: "white",
                  width: "250px",
                  height: "48px",
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontweight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#0C344E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#0C344E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Add New User Role
              </Button>
            </Link>
          </Box>
        </Box>
        <Box marginTop="50px">
          <UserRoleTable />
        </Box>
      </Box>
    </Box>
  );
};

export default UserRole;
