import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveRenewalRequest,
  renewalContractServices,
  renewalListingData,
} from "../services/LeaseApi";
import { toast } from "react-toastify";

const initialState = {
  IsLoading: false,
  IsSuccces: false,
  IsApproved: false,
  listData: [],
};

export const fetchRenewalList = createAsyncThunk(
  "/flat-contracts/admin/renewal-requests",
  async (params) => {
    const response = await renewalListingData(params);
    return response.data.data;
  }
);

export const RenewalLease = createAsyncThunk(
  "/flat-contracts/admin/renewal-requests/approve",
  async (data) => {
    try {
      const { payload, requestId } = data;
      const resp = await approveRenewalRequest(payload, requestId);
      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Request approved successfully");
      }
    } catch (error) {}
  }
);
export const renewalContractApi = createAsyncThunk(
  "/flat-contracts/admin/renewal-requests/renew",
  async (data) => {
    try {
      const { navigate, payload } = data;
      const resp = await renewalContractServices(payload);
      if (resp.data.status == "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Contract renewal request added");
        navigate("/leaserenewal");
      }
    } catch (error) {}
  }
);

const RenewalLeaseSlice = createSlice({
  name: "RenewalLease",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRenewalList.pending, (state) => {
        state.IsLoading = true;
        state.IsSuccces = false;
        state.error = null;
      })
      .addCase(fetchRenewalList.fulfilled, (state, action) => {
        state.IsLoading = false;
        state.IsSuccces = true;
        state.listData = action.payload;
      })
      .addCase(fetchRenewalList.rejected, (state, action) => {
        state.IsLoading = false;
        state.IsSuccces = false;
      })
      .addCase(RenewalLease.pending, (state) => {
        state.IsLoading = true;
        state.IsApproved = false;
      })
      .addCase(RenewalLease.fulfilled, (state, action) => {
        state.IsLoading = false;
        state.IsApproved = true;
      })
      .addCase(RenewalLease.rejected, (state, action) => {
        state.IsLoading = false;
        state.IsApproved = false;
      })
      .addCase(renewalContractApi.pending, (state) => {
        state.IsLoading = true;
        state.IsSuccces = false;
      })
      .addCase(renewalContractApi.fulfilled, (state, action) => {
        state.IsLoading = false;
        state.IsSuccces = true;
      })
      .addCase(renewalContractApi.rejected, (state, action) => {
        state.IsLoading = false;
        state.IsSuccces = false;
      });
  },
});

export default RenewalLeaseSlice.reducer;
