import moment from "moment-timezone";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const formatAMPM = (originalDate) => {
  return moment(originalDate).tz(timezone).format("LT");
};

export const getDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return formattedDate;
};

export const removeCountryCode = (countryCode, number) => {
  const removeCountryCode = number
    ?.toString()
    ?.replace(countryCode, "")
    ?.trim();
  return removeCountryCode;
};

export const getMobileNumber = (code, number) => {
  const countryCode = code?.replace("+", "")?.trim();
  const mobileNumber = number ? countryCode + number : null;
  return {
    countryCode,
    mobileNumber,
  };
};

export const showDash = (type) => {
  const checkingType = type ? type : "-";
  return checkingType;
};
