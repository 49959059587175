import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createNoticeApi,
  fetchNoticeData,
  noticeDataService,
  noticeEditService,
} from "../services/NoticesServise";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isUpdated: false,
  noticeData: [],
  getNoticeDetailData: {},
  editStatus: false,
};

export const createNotices = createAsyncThunk(
  "/notices/create",
  async (data) => {
    try {
      const { navigate, payload, buildingId } = data;
      const resp = await createNoticeApi({ payload, buildingId });
      if (resp.data.status === "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Notice create successfully");
        navigate("/notices");
      }
    } catch (error) {}
  }
);

export const getNoticeData = createAsyncThunk("/v1/notices", async (data) => {
  try {
    const resp = await fetchNoticeData(data);
    return resp.data.data;
  } catch (error) {}
});

export const getNoticeDetails = createAsyncThunk(
  "/particular/notices/",
  async (id) => {
    try {
      const resp = await noticeDataService(id);
      return resp.data.data;
    } catch (error) {}
  }
);

export const patchNoticeDetails = createAsyncThunk(
  "/patch/notices/",
  async (data, thunkApi) => {
    try {
      const resp = await noticeEditService(data);
      if (resp.data.status === "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Notice Update Successfully");
        // navigate("/notices");
      }
    } catch (error) {}
  }
);

const NoticesSlice = createSlice({
  name: "Notices",
  initialState,
  reducers: {},

  extraReducers: {
    [createNotices.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createNotices.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createNotices.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getNoticeData.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getNoticeData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.noticeData = action.payload;
    },
    [getNoticeData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getNoticeDetails.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getNoticeDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getNoticeDetailData = action.payload;
    },
    [getNoticeDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [patchNoticeDetails.pending]: (state, action) => {
      state.isLoading = true;
      state.isUpdated = false;
    },
    [patchNoticeDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
    },
    [patchNoticeDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.isUpdated = false;
    },
  },
});

export default NoticesSlice.reducer;
