import * as yup from "yup";

export const unitSchema = yup.object().shape({
  name_en: yup.string().trim().required("This field is required"),
  buildingId: yup.string().trim().required("This field is required"),
  flatType: yup.string().required("This field is required"),
  contactEmail: yup
    .string()
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, {
      message: "Invalid Contact Email",
      excludeEmptyString: true,
    }),
  contactMobileNumber: yup
    .string()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/, {
      message: "Phone number is not valid",
      excludeEmptyString: true,
    }),
});

export const userSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
      message: "Number and extra space are not acceptable",
    }),
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().when('countryCode', (countryCode, schema) => {
    return schema.test('is-valid', 'Invalid mobile number', function (value) {
      if (countryCode?.length + value?.length === 13) {
        return /^[1-9]\d*$/.test(value)
      } else {
        return false;
      }
    })
  }),
  email: yup
    .string()
    .test("Invalid Email", (value) =>
      [/^[^0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/].some((pattern) =>
        pattern.test(value)
      )
    ),
  alternateCountryCode: yup.string().nullable(),
  alternateMobileNumber: yup.string().nullable().when('alternateCountryCode', (alternateCountryCode, schema) => {
    return schema.test('is-valid', 'Invalid mobile number', function (value) {
      if (value === "") return true;
      else if (alternateCountryCode?.length + value?.length === 13) {
        return /^[1-9]\d*$/.test(value)
      } else {
        return false;
      }
    })
  }),
  alternateEmail: yup
    .string().nullable()
    .matches(/^[^0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Invalid Contact Email",
      excludeEmptyString: true,
    }),
  accountNumber: yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .test('empty-or-digits', 'Account number can only contain digits', function (value) {
      if (value === null || value === '') return true; // Allow null or empty string
      return /^[0-9]\d*$/.test(value);
    })
    .min(6, 'Account number must be at least 6 digits')
    .max(20, 'Account number must be at most 20 digits')
});

export const tenantSchema = yup.object().shape({
  TenantName: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
      message: "Number and extra space are not acceptable",
    }),
  TenantEmail: yup
    .string()
    .test("Invalid Email", (value) =>
      [/^[^0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/].some((pattern) =>
        pattern.test(value)
      )
    ),
  TenantMobileNumber: yup
    .string()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/, {
      message: "Phone number is not valid",
      excludeEmptyString: true,
    }),
})

export const buildingSchema = yup.object().shape({
  name_en: yup.string().trim().required("This field is required"),
  localityId: yup.string().trim().required("This field is required"),
  contactName:yup
  .string().trim().nullable()
  .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, {
    message: "Number and extra space are not acceptable",
    excludeEmptyString: true,
  }),
  contactEmail: yup
    .string()
    .matches(/^[^0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Invalid Contact Email",
      excludeEmptyString: true,
    }),
  contactCountryCode: yup.string().required('Country code is required'),
  contactMobileNumber: yup.string().when('contactCountryCode', (contactCountryCode, schema) => {
    return schema.test('is-valid', 'Invalid mobile number', function (value) {
      if (value === null || value === '') return true;
      if (contactCountryCode?.length + value?.length === 13) {
        return /^[1-9]\d*$/.test(value)
      } else {
        return false;
      }
    })
  }),
});

export const buildingSchemaForEdit = yup.object().shape({
  name_en: yup.string().trim().required("This field is required"),
  contactEmail: yup
    .string()
    .matches(/^[^0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Invalid Contact Email",
      excludeEmptyString: true,
    }),
  contactMobileNumber: yup
    .string()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]/, {
      message: "Phone number is not valid",
      excludeEmptyString: true,
    }),
});

export const helpLineSchema = yup.object().shape({
  name_en: yup.string().trim().required("This field is required"),
  contactNumber: yup.number().required("This field is required"),
  // buildings: yup.array().min(1, "This field is required"),
});

export const resetPassword = yup.object().shape({
  newPassword: yup
    .string()
    .required("This field is required")
    .test(
      "Password required",
      "Password should be atleast 8 characters long consisting of both upper and lower case characters and should contain at least 1 number and 1 special character",
      (value) =>
        [
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{6,}$/,
        ].some((pattern) => pattern.test(value))
    ),
});

export const noticeSchema = yup.object().shape({
  title: yup.string().required("This field is required"),
  category: yup.string().required("This field is required"),
  validFrom: yup.date().required("This field is required"),
  validTill: yup.date().required("This filed is required"),
});

export const approveDeny = yup.object().shape({
  contractStartDate: yup.date().required("This field is required"),
  contractEndDate: yup.date().required("This field is required"),
  flatUsage: yup.string().required("This filed is required"),
  paymentFrequency: yup.string().required("This filed is required"),
  paymentMode: yup.string().required("This field is required"),
  securityDeposit: yup.number().required("This field is required"),
  rentAmount: yup.number().required("This field is required"),
  noticePeriod: yup.number().required("This field is required"),
  activationFee: yup.number().required("This field is required"),
});

export const guardSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  userName: yup.string().required("This field is required"),
  mobileNumber: yup.string().required("This filed is required"),
  password: yup
    .string()
    .required("This field is required")
    .test(
      "Password required",
      "Password should be atleast 8 characters long consisting of both upper and lower case characters and should contain at least 1 number and 1 special character",
      (value) =>
        [
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        ].some((pattern) => pattern.test(value))
    ),
});

export const editGuardSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  userName: yup.string().required("This field is required"),
  mobileNumber: yup.string().required("This filed is required"),
});

export const categorySchema = yup.object().shape({
  name_en: yup.string().required("This field is required"),
  image: yup.string().required("This field is required"),
});
