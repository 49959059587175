import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function DailogBox({ handleClose, openModel, setValue, children }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModel}
      >
        <DialogContent dividers>
          <Box sx={{ float: "right" }}>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Box>{children}</Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
export default React.memo(DailogBox);
