import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createHelplineApi,
  fetchHelplineData,
  fetchParticularHelplineDetail,
  iconsListApi,
  updateHelplineService,
} from "../services/helplineServise";

const initialState = {
  isLoading: false,
  isSuccess: false,
  helplineData: [],
  helplineInfo: {},
  imageIconsData: [],
};

export const createHelpline = createAsyncThunk("/helplines", async (data) => {
  try {
    const { navigate, payload } = data;
    const resp = await createHelplineApi({ payload });
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Emergency Contact added successfully");
      navigate("/helpline");
    }
  } catch (error) {}
});

export const getHelplineData = createAsyncThunk(
  "/get/helpline",
  async (data) => {
    try {
      const resp = await fetchHelplineData(data);
      return resp.data.data;
    } catch (error) {}
  }
);

export const getParticularHelplineDetail = createAsyncThunk(
  "/particular/helpline",
  async (id) => {
    try {
      const resp = await fetchParticularHelplineDetail(id);
      return resp.data.data;
    } catch (error) {}
  }
);

export const updateHelpline = createAsyncThunk(
  "/helpline/update",
  async (data) => {
    try {
      const { payload, helplineId } = data;
      const resp = await updateHelplineService({ payload, helplineId });
      toast.success(resp?.data?.data);
    } catch (error) {
      toast.error(error);
    }
  }
);

export const fetchDumpImageList = createAsyncThunk(
  "/dumpImage",
  async (data) => {
    try {
      const resp = await iconsListApi(data);
      return resp.data.data;
    } catch (error) {}
  }
);

const HelplineSlice = createSlice({
  name: "Helpline",
  initialState,
  reducers: {},

  extraReducers: {
    [createHelpline.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createHelpline.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createHelpline.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getHelplineData.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getHelplineData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.helplineData = action.payload;
    },
    [getHelplineData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getParticularHelplineDetail.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getParticularHelplineDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.helplineInfo = action.payload;
    },
    [getParticularHelplineDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [fetchDumpImageList.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchDumpImageList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.imageIconsList = action.payload;
    },
    [fetchDumpImageList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [updateHelpline.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [updateHelpline.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [updateHelpline.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default HelplineSlice.reducer;
