import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllProperties from "./AllProperties/index";
import Units from "./Units/index";
import AddUnit from "./AddUnit/index";
import PropertyName from "./PropertyName";
import MyProfile from "./MyProfile";
import Preferences from "./Preferences";
import User from "./User";
import UserMapping from "./UserMapping";
import Staff from "./Staffs";
import AddUser from "./AddUser";
import VisitorManagement from "./VisitorManagement";
import AddNewProperty from "./AllProperties/AddNewProperty";
import EditProperty from "./EditProperty/EditProperty";
import EditUnitDetail from "./EditUnitDetail.js";
import UnitDetail from "./UnitDetail";
import EditUserDetail from "./EditUserDetail";
import UserDetail from "./UserDetail";
import Aminities from "./Aminities";
import AllPosts from "./communityNotices/AllPost/index";
import ReportedPostMain from "./communityNotices/ReportedPost/index";
import CreateAnnouncement from "./communityNotices/CreateAnnouncement/index";
import CreateEvent from "./communityNotices/CreateEvent/index";
import AllRequest from "./ServisesRequest/AllRequest/index";
import GenerateInvoice from "./ServisesRequest/GenerateInvoice/index";
import AllRequestProperty from "./ServisesRequest/AllRequest/AllRequestProperty/index";
import GenerateInvoiceProperty from "./ServisesRequest/GenerateInvoice/GenerateInvoiceProperty/index";
import CreateChargesCatalouge from "./ServisesRequest/ChargesCatalogue/CreateChargesCatalouge/index";
import EditCatalouge from "./ServisesRequest/ChargesCatalogue/EditChargesCatalouge/index";
import ChargesCatalouge from "./ServisesRequest/ChargesCatalogue/ChargesCatalouge/index";
import AllInvoice from "./Payment/AllInvoice/index";
import CreateInvoice from "./Payment/CreateInvoice/index";
import Reminder from "./Payment/Reminder/index";
import Budget from "./Payment/Budget/index";
import AddBudget from "./Payment/Budget/AddBudget/index.jsx";
import EditBudget from "./Payment/Budget/BudgetDetail/index.jsx";
import ExpenseDetails from "./Payment/ExpensesDetails/index";
import TaxesDropDown from "./TaxesDropDown/TaxesDropDown";
import Notification from "./Notification";
import Login from "./login/index";
import Dashboard from "./Dashboard/index";
import AddStaff from "./Staffs/AddStaff/index";
import StaffDetail from "./StaffDetail/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AccountBox } from "../src/components/accountBox/index";
import ResetPassword from "./ResetPassword/index";
import Protected from "./Protected";
import Guardmangement from "./GuardManagement/GuardManagement/index";
import AddGuard from "./GuardManagement/AddGuard/Index";
import EditGuard from "./GuardManagement/GuardDetails/index";
import Visitordetail from "./VisitorManagement/VisiterDetail/index";
import AddAllRequestMain from "./ServisesRequest/AddAllRequest/index";
import LeftNav from "./components/leftNav";
import TopNav from "./components/topNav";
import { Grid } from "@mui/material";
import VisitorStats from "./Dashboard/VisitorStats";
import Lease from "./Lease/Index";
import AddLease from "./Lease/AddLease/Index";
import LeaseDetailAndEdit from "./Lease/EditAndView/LeaseDetail";
import Notices from "./Notices/Notices";
import CreateNotices from "./Notices/CreateNotices/CreateNotices";
import Helpline from "./Helpline/Helpline";
import createHelpLine from "./Helpline/createHelpLine/CreateHelpLine";
import NoticesDetail from "./Notices/NoticesDetail/NoticesDetail";
import HelplineDetail from "./Helpline/HelplineDetail/HelplineDetail";
import Asset from "./assetManagement/Asset";
import AddAsset from "./assetManagement/AddAsset";
import AssetDetail from "./assetManagement/AssetDetail";
import UserRole from "./UserRole/UserRole";
import AddUserRole from "./UserRole/AddUserRole";
import Category from "./ServisesRequest/Category/Category";
import AddCategory from "./ServisesRequest/Category/AddCategory";
import Preventative from "./preventative/Preventative";
import AddPreventative from "./preventative/AddPreventative";
import PreventativeDetails from "./preventative/PreventativeDetails";
import PropertyOverview from "./Dashboard/PropertyOverview";
import React from "react";
import RenewalLease from "./Lease/Lease Renewal/RenewalLease";
import AddRenewal from "./Lease/Lease Renewal/AddRenewal";
import { useEffect } from "react";

// import Notices from "./Notices/in"

function App() {
  useEffect(()=>{
    window.location.replace('https://www.propertyportal.livo.ae/admin')
  },[])
  return (
    // <Grid sx={{ height: "100vh", overflow: "hidden" }}>
    //   <BrowserRouter>
    //     <TopNav />
    //     <Grid display="flex">
    //       <LeftNav />
    //       <Routes>
    //         <Route
    //           path="/allproperties"
    //           element={<Protected Component={AllProperties} />}
    //         ></Route>
    //         <Route
    //           path="/units"
    //           element={<Protected Component={Units} />}
    //         ></Route>
    //         <Route
    //           path="/preventative"
    //           element={<Protected Component={Preventative} />}
    //         ></Route>{" "}
    //         <Route
    //           path="/preventative/:id"
    //           element={<Protected Component={PreventativeDetails} />}
    //         ></Route>{" "}
    //         <Route
    //           path="/add-preventative"
    //           element={<Protected Component={AddPreventative} />}
    //         ></Route>
    //         <Route
    //           path="/addunit"
    //           element={<Protected Component={AddUnit} />}
    //         ></Route>
    //         <Route
    //           path="/addstaff"
    //           element={<Protected Component={AddStaff} />}
    //         ></Route>
    //         <Route
    //           path="/unitdetail/:id"
    //           element={<Protected Component={UnitDetail} />}
    //         ></Route>
    //         <Route
    //           path="/editunitdetail"
    //           element={<Protected Component={EditUnitDetail} />}
    //         ></Route>
    //         <Route
    //           path="/property/:id"
    //           element={<Protected Component={PropertyName} />}
    //         ></Route>
    //         <Route
    //           path="/profile"
    //           element={<Protected Component={MyProfile} />}
    //         ></Route>
    //         <Route
    //           path="/Lease"
    //           element={<Protected Component={Lease} />}
    //         ></Route>
    //         <Route
    //           path="/addlease"
    //           element={<Protected Component={AddLease} />}
    //         ></Route>
    //         <Route
    //           path="/leaserenewal"
    //           element={<Protected Component={RenewalLease} />}
    //         ></Route>
    //         <Route
    //           path="/addrenewallease"
    //           element={<Protected Component={AddRenewal} />}
    //         ></Route>
    //         <Route
    //           path="/addnotices"
    //           element={<Protected Component={CreateNotices} />}
    //         ></Route>
    //         <Route
    //           path="/noticeDetail/:id"
    //           element={<Protected Component={NoticesDetail} />}
    //         ></Route>
    //         <Route
    //           path="/notices"
    //           element={<Protected Component={Notices} />}
    //         ></Route>
    //         <Route
    //           path="/leasedetail/:id"
    //           element={<Protected Component={LeaseDetailAndEdit} />}
    //         ></Route>
    //         <Route
    //           path="/preferences"
    //           element={<Protected Component={Preferences} />}
    //         ></Route>
    //         <Route
    //           path="/taxesdropdown"
    //           element={<Protected Component={TaxesDropDown} />}
    //         ></Route>
    //         <Route
    //           path="/userrole"
    //           element={<Protected Component={UserRole} />}
    //         ></Route>
    //         <Route
    //           path="/add-userrole"
    //           element={<Protected Component={AddUserRole} />}
    //         ></Route>
    //         <Route
    //           path="/notification"
    //           element={<Protected Component={Notification} />}
    //         ></Route>
    //         <Route
    //           path="/addnewproperty"
    //           element={<Protected Component={AddNewProperty} />}
    //         ></Route>
    //         <Route
    //           path="/editproperty"
    //           element={<Protected Component={EditProperty} />}
    //         ></Route>
    //         <Route
    //           path="/user"
    //           element={<Protected Component={User} />}
    //         ></Route>
    //         <Route
    //           path="/helpline"
    //           element={<Protected Component={Helpline} />}
    //         ></Route>
    //         <Route
    //           path="/helplinedetail/:id"
    //           element={<Protected Component={HelplineDetail} />}
    //         ></Route>
    //         <Route
    //           path="/createhelpline"
    //           element={<Protected Component={createHelpLine} />}
    //         ></Route>
    //         <Route
    //           path="/usermapping"
    //           element={<Protected Component={UserMapping} />}
    //         ></Route>
    //         <Route
    //           path="/adduser"
    //           element={<Protected Component={AddUser} />}
    //         ></Route>
    //         <Route
    //           path="/userdetail/:id"
    //           element={<Protected Component={UserDetail} />}
    //         ></Route>
    //         <Route
    //           path="/edituserdetail"
    //           element={<Protected Component={EditUserDetail} />}
    //         ></Route>
    //         <Route
    //           path="/aminities"
    //           element={<Protected Component={Aminities} />}
    //         ></Route>
    //         <Route
    //           path="/staff"
    //           element={<Protected Component={Staff} />}
    //         ></Route>
    //         <Route
    //           path="/guard"
    //           element={<Protected Component={Guardmangement} />}
    //         ></Route>
    //         <Route
    //           path="/addguard"
    //           element={<Protected Component={AddGuard} />}
    //         ></Route>
    //         <Route
    //           path="/editguards/:guardId"
    //           element={<Protected Component={EditGuard} />}
    //         ></Route>
    //         <Route
    //           path="/asset"
    //           element={<Protected Component={Asset} />}
    //         ></Route>
    //         <Route
    //           path="/asset/:id"
    //           element={<Protected Component={AssetDetail} />}
    //         ></Route>
    //         <Route
    //           path="/add-asset"
    //           element={<Protected Component={AddAsset} />}
    //         ></Route>
    //         <Route
    //           path="/staffs/:id"
    //           element={<Protected Component={StaffDetail} />}
    //         ></Route>
    //         <Route
    //           path="/visitor"
    //           element={<Protected Component={VisitorManagement} />}
    //         ></Route>
    //         <Route
    //           path="/visitordetail/:id"
    //           element={<Protected Component={Visitordetail} />}
    //         ></Route>
    //         <Route
    //           path="/property"
    //           element={<Protected Component={PropertyName} />}
    //         ></Route>
    //         <Route
    //           path="/allposts"
    //           element={<Protected Component={AllPosts} />}
    //         ></Route>
    //         <Route
    //           path="/reportedpost"
    //           element={<Protected Component={ReportedPostMain} />}
    //         ></Route>
    //         <Route
    //           path="/createannouncement"
    //           element={<Protected Component={CreateAnnouncement} />}
    //         ></Route>
    //         <Route
    //           path="/addallrequest"
    //           element={<Protected Component={AddAllRequestMain} />}
    //         ></Route>
    //         <Route
    //           path="/createvent"
    //           element={<Protected Component={CreateEvent} />}
    //         ></Route>
    //         <Route
    //           path="/allrequest"
    //           element={<Protected Component={AllRequest} />}
    //         ></Route>
    //         <Route
    //           path="/category"
    //           element={<Protected Component={Category} />}
    //         ></Route>
    //         <Route
    //           path="/addcategory"
    //           element={<Protected Component={AddCategory} />}
    //         ></Route>
    //         <Route
    //           path="/generateinvoice"
    //           element={<Protected Component={GenerateInvoice} />}
    //         ></Route>
    //         <Route
    //           path="/allrequestproperty"
    //           element={<Protected Component={AllRequestProperty} />}
    //         ></Route>
    //         <Route
    //           path="/allrequest/:id"
    //           element={<Protected Component={AllRequestProperty} />}
    //         ></Route>
    //         <Route
    //           path="generateinvoiceproperty"
    //           element={<Protected Component={GenerateInvoiceProperty} />}
    //         ></Route>
    //         <Route
    //           path="generateinvoice/:id"
    //           element={<Protected Component={GenerateInvoiceProperty} />}
    //         ></Route>
    //         <Route
    //           path="/createchargescatalouge"
    //           element={<Protected Component={CreateChargesCatalouge} />}
    //         ></Route>
    //         <Route
    //           path="/editchargescatalouge"
    //           element={<Protected Component={EditCatalouge} />}
    //         ></Route>
    //         <Route
    //           path="/chargescatalouge"
    //           element={<Protected Component={ChargesCatalouge} />}
    //         ></Route>
    //         <Route
    //           path="/allinvoice"
    //           element={<Protected Component={AllInvoice} />}
    //         ></Route>
    //         <Route
    //           path="/createinvoice"
    //           element={<Protected Component={CreateInvoice} />}
    //         ></Route>
    //         <Route
    //           path="/reminder"
    //           element={<Protected Component={Reminder} />}
    //         ></Route>
    //         <Route
    //           path="/budget"
    //           element={<Protected Component={Budget} />}
    //         ></Route>
    //         <Route
    //           path="/addbudget"
    //           element={<Protected Component={AddBudget} />}
    //         ></Route>
    //         <Route
    //           path="/budgetdetail"
    //           element={<Protected Component={EditBudget} />}
    //         ></Route>
    //         <Route
    //           path="/expensesdetails"
    //           element={<Protected Component={ExpenseDetails} />}
    //         ></Route>
    //         <Route path="/" element={<AccountBox />}></Route>
    //         <Route
    //           path="/dashboard"
    //           element={<Protected Component={Dashboard} />}
    //         ></Route>
    //         <Route
    //           path="/propertyoverview"
    //           element={<Protected Component={PropertyOverview} />}
    //         ></Route>
    //         <Route
    //           path="/visitorstats"
    //           element={<Protected Component={VisitorStats} />}
    //         ></Route>
    //         <Route
    //           path="/resetpassword"
    //           element={<Protected Component={ResetPassword} />}
    //         ></Route>
    //         <Route
    //           path="*"
    //           exact={true}
    //           element={<Protected Component={Dashboard} />}
    //         ></Route>
    //         {/* <Route path="*" exact={true} element={Login}></Route> */}
    //       </Routes>
    //       <ToastContainer />
    //     </Grid>
    //   </BrowserRouter>
    // </Grid>
    <></>
  );
}
export default React.memo(App);
