import { createContext, useState } from "react";
import { getAllBuilding } from "../services/allPropertiesAPI";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

export const buildingContext = createContext({});

export const BuildingProvider = ({ children }) => {
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(
        sessionStorage.getItem("building")
            ? sessionStorage.getItem("building")
            : ""
    );
    

    const fetchBuildings = async () => {
        const response = await getAllBuilding();
        setBuildings(response?.data?.data?.rows);
    };

    const onBuildingSelect = (buildingId) => {
        sessionStorage.setItem("building", buildingId);
        setSelectedBuilding(buildingId);
    };

    return (
        <buildingContext.Provider
            value={{
                buildings,
                fetchBuildings,
                onBuildingSelect,
                selectedBuilding,
            }}
        >
            {children}
        </buildingContext.Provider>
    );
};

// export const flatContext = createContext({});

// export const FlatProvider = ({ children }) => {
//     const [unitDetail, setunitDetail] = useState([]);

//     const fetchBuildings = async () => {
//         const response = await getAllBuilding();
//         setunitDetail(response.data.data.rows);
//     };

//     return (
//         <flatContext.Provider
//             value={{
//                 unitDetail,
//             }}
//         >
//             {children}
//         </flatContext.Provider>
//     );
// };
