import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, MenuItem, TextField } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  open,
  handleClose,
  setSelectedFilterType,
  selectedFilterType,
  children,
  AddFilter,
  selectedPurpose,
  formatStartDate,
  formatEndDate,
  selectedUnit,
  selectedStatus,
}) {
  const filteList = ["Purpose", "Date", "Unit No", "Lease status"];
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ px: 2 }}>
            <Box className="d-flex justify-content-between mb-3 mt-3">
              <Typography variant="h5">Add Filters</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  handleClose();
                  AddFilter(
                    selectedPurpose,
                    formatStartDate,
                    formatEndDate,
                    selectedUnit,
                    selectedStatus
                  );
                }}
                sx={{
                  background: "#0C344E",
                  textTransform: "capitalize",
                  width: "100px",
                  height: "40px",
                  borderRadius: "10px",
                }}
              >
                Save
              </Button>
            </Box>

            <Box>
              <TextField
                select
                label="Choose filter type"
                inputProps={{ maxLength: 100 }}
                multiline
                maxRows={4}
                maxLength={10}
                name="building"
                variant="standard"
                value={selectedFilterType}
                onChange={(e) => {
                  setSelectedFilterType(e.target.value);
                }}
                style={{
                  fontFamily: "Barlow",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "300px",
                  lineHeight: "19px",
                }}
              >
                {filteList?.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Box>{children}</Box>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
