import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addPreventativeService,
  editPreventativeDetailService,
  getCategoriesService,
  getPreventativeDetailService,
  getPreventativeListingService,
} from "../services/preventativeService";

const initialState = {
  isLoading: false,
  isSuccess: false,
  preventativeListingData: [],
  preventativeDetailData: [],
  getCategoriesData: [],
};

export const addPreventativeSlice = createAsyncThunk(
  "/preventative/create",
  async (payload) => {
    try {
      const { dataRest, navigate } = payload;
      const resp = await addPreventativeService(dataRest);
      if (resp.data.status === "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success("Request create successfully");
        navigate("/preventative");
      }
    } catch (error) {}
  }
);

export const PreventativeListingSlice = createAsyncThunk(
  "preventativeListing",
  async (data) => {
    try {
      const resp = await getPreventativeListingService(data);
      return resp.data.data;
    } catch (error) {}
  }
);

export const PreventativeDetailSlice = createAsyncThunk(
  "ppmDetail",
  async (id) => {
    try {
      const resp = await getPreventativeDetailService(id);
      return resp.data.data;
    } catch (error) {}
  }
);

export const CategoryDetailSlice = createAsyncThunk("ppmDetail", async () => {
  try {
    const resp = await getCategoriesService();
    return resp.data.data;
  } catch (error) {}
});

export const editPreventativeSlice = createAsyncThunk(
  "/preventative/edit",
  async (payload) => {
    try {
      const resp = await editPreventativeDetailService(payload);
      if (resp.data.status === "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success(resp?.data?.data);
        // navigate("/asset");
      }
    } catch (error) {}
  }
);

const Preventative = createSlice({
  name: "Preventative",
  initialState,
  reducers: {},

  extraReducers: {
    // editPreventativeSlice
    [editPreventativeSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editPreventativeSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editPreventativeSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // addPreventativeSlice
    [addPreventativeSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addPreventativeSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [addPreventativeSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // PreventativeListingSlice
    [PreventativeListingSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [PreventativeListingSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.preventativeListingData = action.payload;
    },
    [PreventativeListingSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // PreventativeDetailsSlice
    [PreventativeDetailSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [PreventativeDetailSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.preventativeDetailData = action.payload;
    },
    [PreventativeDetailSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // category lisitng
    [CategoryDetailSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CategoryDetailSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getCategoriesData = action.payload;
    },
    [CategoryDetailSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default Preventative.reducer;
