import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Box, MenuItem, TextField } from "@mui/material";
import { formatAMPM } from "../../../util";
import "./index.css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getAllBuilding } from "../../../services/allPropertiesAPI";
import {
  getflatDetail,
  getflatDetailforRequestEdit,
} from "../../../services/allCommunityApi";
import { updateServisesRequest } from "../../../services/allServisesApi";
import moment from "moment";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="growDailoc">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditDetaildailog({
  editdetailOpen,
  EditDetailsClose,
  generatedBy,
  property,
  unitNo,
  preferredTime,
  id,
  fetchAllRequestProperty,
  OneEgoTime,
  setOneEgoTime,
}) {
  const [handlePropertyfiled, setHandlePropertyfiled] = useState({
    property: {
      label: "",
      value: "",
    },
    unitNo: {
      label: "",
      value: "",
    },
    requestedTimeSlot: "",
    requestedDateSlot: "",
  });
  let gettime = formatAMPM(preferredTime?.end);

  const [handleEndTime, sethandleEndTime] = useState(gettime);

  useEffect(() => {
    sethandleEndTime(gettime);
  }, [gettime]);

  useEffect(() => {
    let formatTime = handlePropertyfiled.requestedTimeSlot;

    setHandlePropertyfiled((prev) => ({
      ...prev,
      requestedTimeSlot: formatAMPM(preferredTime?.start),
      requestedDateSlot: preferredTime?.start,
    }));
  }, [property, unitNo]);

  const [AllBuilding, setAllBuilding] = useState([]);
  const [allFLat, setAllFLat] = useState([]);

  const fetchAllBuilding = async () => {
    const resp = await getAllBuilding();

    setAllBuilding(resp.data.data.rows);
  };

  const fetchFlatData = async (buildingId) => {
    const resp = await getflatDetailforRequestEdit({ buildingId });

    setAllFLat(resp?.data?.data?.rows && resp?.data?.data.rows);
  };

  useEffect(() => {
    fetchAllBuilding();
  }, []);

  useEffect(() => {
    fetchFlatData(handlePropertyfiled.property.value);
  }, []);

  useEffect(() => {
    if (allFLat?.length) {
      setHandlePropertyfiled((prevState) => {
        const filterFlat = allFLat?.filter(
          (building) => building?.name_en === unitNo
        )?.[0];

        return {
          ...prevState,
          unitNo: {
            label: filterFlat?.name_en,
            value: filterFlat?.flatInfo?.flatId,
          },
        };
      });
    }
    if (AllBuilding?.length) {
      setHandlePropertyfiled((prevState) => {
        const filterBuilding = AllBuilding?.filter(
          (building) => building?.name_en === property
        )?.[0];
        return {
          ...prevState,
          property: {
            label: filterBuilding?.name_en,
            value: filterBuilding?.id,
          },
        };
      });
    }
  }, [unitNo, allFLat, AllBuilding]);

  let formatStartDate = moment(handlePropertyfiled?.requestedDateSlot).format(
    "YYYY/MM/DD"
  );

  let time = moment(
    `${formatStartDate} ${handlePropertyfiled?.requestedTimeSlot}`
  ).format();

  localStorage.setItem("flatId", handlePropertyfiled.unitNo.value);
  const getUpdateRequestData = async () => {
    try {
      const requestId = id;
      let data = {
        ...(generatedBy == "Admin" && {
          flatId: handlePropertyfiled.unitNo.value,
        }),
        time: time,
      };

      const resp = await updateServisesRequest(requestId, {
        data,
      });

      if (resp.data.status == "success") {
        fetchAllRequestProperty();
        toast.success(resp.data.data);
      } else {
        toast.error(resp.data.msg);
      }
    } catch (err) {
      toast.error(err);
    }
  };
  const timeArr = [
    { time: "8:00 AM", value: 1 },
    { time: "9:00 AM", value: 2 },
    { time: "10:00 AM", value: 3 },
    { time: "11:00 AM", value: 4 },
    { time: "12:00 PM", value: 5 },
    { time: "1:00 PM", value: 6 },
    { time: "2:00 PM", value: 7 },
    { time: "3:00 PM", value: 8 },
    { time: "4:00 PM", value: 9 },
    { time: "5:00 PM", value: 10 },
  ];

  const getOneEgoTime = (e) => {
    setHandlePropertyfiled({
      ...handlePropertyfiled,
      requestedTimeSlot: e.target.value,
    });

    const initialTime = e.target.value;
    const initialArr = initialTime.split(" "); // ["3:00", "AM/PM"]
    const hourValue = +initialArr[0].split(":")[0];
    const finalTime =
      `${hourValue == 12 ? 1 : hourValue + 1}:00` +
      " " +
      `${hourValue == 11 ? "PM" : initialArr[1]}`;

    setOneEgoTime(finalTime);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={EditDetailsClose}
        aria-labelledby="customized-dialog-title"
        open={editdetailOpen}
        className="details-dialog"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={EditDetailsClose}
        >
          {generatedBy === "Admin" ? (
            <>
              <div>
                <h5>Update Request Details</h5>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    select
                    label="Select Building"
                    inputProps={{ maxLength: 100 }}
                    className="inputopacity"
                    multiline
                    maxRows={4}
                    maxLength={10}
                    name="building"
                    value={handlePropertyfiled?.property?.label}
                    onChange={(e) => {
                      setHandlePropertyfiled({
                        ...handlePropertyfiled,
                        property: e.target.value,
                      });
                    }}
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "450px",
                      lineHeight: "19px",
                      marginRight: "75px",
                    }}
                  >
                    {AllBuilding &&
                      AllBuilding?.map((option) => {
                        return (
                          <MenuItem
                            key={option.id}
                            value={{
                              label: option?.name_en,
                              value: option?.id,
                            }}
                          >
                            {option.name_en}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>
                <div className="mt-2">
                  <TextField
                    id="standard-multiline-flexible2"
                    select
                    label="Select Unit"
                    inputProps={{ maxLength: 100 }}
                    className="inputopacity"
                    maxRows={4}
                    name="unit"
                    maxLength={10}
                    value={handlePropertyfiled.unitNo.label}
                    onChange={(e) => {
                      setHandlePropertyfiled({
                        ...handlePropertyfiled,
                        unitNo: e.target.value,
                      });
                    }}
                    variant="standard"
                    style={{
                      fontFamily: "Barlow",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      width: "450px",
                      marginRight: "75px",
                    }}
                  >
                    {allFLat &&
                      allFLat?.map((option) => {
                        return (
                          <MenuItem
                            key={option.id}
                            value={{
                              label: option?.name_en,
                              value: option?.flatInfo?.flatId,
                            }}
                          >
                            {option.name_en}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </div>

                <div style={{ marginTop: "15px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    className="dateFeild"
                  >
                    <DesktopDatePicker
                      disablePast
                      variant="standard"
                      className="changecolor"
                      inputFormat="YYYY/MM/DD"
                      label="Select Reference Date"
                      value={handlePropertyfiled?.requestedDateSlot}
                      onChange={(e) =>
                        setHandlePropertyfiled({
                          ...handlePropertyfiled,
                          requestedDateSlot: e.$d,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "86% !important",
                            marginTop: "12px",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="d-flex gap-2">
                  <div style={{ marginTop: "15px" }}>
                    <TextField
                      id="standard-multiline-flexible2"
                      select
                      label="Preferred Time Start"
                      inputProps={{ maxLength: 100 }}
                      className="inputopacity"
                      maxRows={4}
                      name="unit"
                      maxLength={10}
                      value={handlePropertyfiled?.requestedTimeSlot}
                      onChange={(e) => getOneEgoTime(e)}
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "220px",
                      }}
                    >
                      {timeArr?.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.time}>
                            {option.time}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <TextField
                      id="standard-multiline-flexible2"
                      label="Preferred Time End"
                      inputProps={{ maxLength: 100 }}
                      className="inputopacity"
                      maxRows={4}
                      name="unit"
                      maxLength={10}
                      value={OneEgoTime}
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "220px",
                      }}
                    ></TextField>
                  </div>
                </div>
                <button
                  style={{
                    background: "#0C344E",
                    border: "none",
                    borderRadius: "5px ",
                    marginTop: "10px",
                    color: "white",
                    fontWeight: "500px !important",
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    getUpdateRequestData();
                    EditDetailsClose();
                  }}
                >
                  Update Request
                </button>
              </div>
            </>
          ) : (
            <>
              <div>
                <h5>Update Request Details</h5>
                <div style={{ marginTop: "15px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    className="dateFeild"
                  >
                    <DesktopDatePicker
                      disablePast
                      variant="standard"
                      className="changecolor"
                      inputFormat="YYYY/MM/DD"
                      label="Select Reference Date"
                      value={handlePropertyfiled?.requestedDateSlot}
                      onChange={(e) =>
                        setHandlePropertyfiled({
                          ...handlePropertyfiled,
                          requestedDateSlot: e.$d,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "100% !important",
                            marginTop: "12px",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="d-flex gap-2">
                  <div style={{ marginTop: "15px" }}>
                    <TextField
                      id="standard-multiline-flexible2"
                      select
                      label="Preferred Time Start"
                      inputProps={{ maxLength: 100 }}
                      className="inputopacity"
                      maxRows={4}
                      name="unit"
                      maxLength={10}
                      value={handlePropertyfiled?.requestedTimeSlot}
                      onChange={(e) => getOneEgoTime(e)}
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "220px",
                      }}
                    >
                      {timeArr?.map((option) => {
                        return (
                          <MenuItem key={option.value} value={option.time}>
                            {option.time}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <TextField
                      id="standard-multiline-flexible2"
                      label="Preferred Time End"
                      inputProps={{ maxLength: 100 }}
                      className="inputopacity"
                      maxRows={4}
                      name="unit"
                      maxLength={10}
                      value={OneEgoTime}
                      variant="standard"
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        width: "220px",
                      }}
                    ></TextField>
                  </div>
                </div>
                <button
                  style={{
                    background: "#0C344E",
                    border: "none",
                    borderRadius: "5px ",
                    marginTop: "10px",
                    color: "white",
                    fontWeight: "500px !important",
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    getUpdateRequestData();
                    EditDetailsClose();
                  }}
                >
                  Update Request
                </button>
              </div>
            </>
          )}
        </BootstrapDialogTitle>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
