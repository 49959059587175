import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSoftDeleteProperty,
  postAddNewBuilding,
} from "../services/allPropertiesAPI";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isDeleteSuccess: false,
};

export const deletePropertyDetail = createAsyncThunk(
  "deleteProperty",
  async (buildingForDelete) => {
    try {
      const resp = await getSoftDeleteProperty(buildingForDelete);
      if (resp.data.status === "success") {
        toast.success(resp.data.msg);
      } else {
        toast.error(resp.data.msg);
      }
    } catch (error) {}
  }
);

export const addProperty = createAsyncThunk("Property/post", async (data) => {
  try {
    const { getData, navigate } = data;
    const resp = await postAddNewBuilding(getData);
    if (resp.data.status == "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Property created successfully");
      navigate("/allproperties");
    }
  } catch (error) {
    // toast.error(error.message, "err");
  }
});

const propertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {},

  extraReducers: {
    [deletePropertyDetail.pending]: (state, action) => {
      state.isLoading = true;
      state.isDeleteSuccess = false;
    },
    [deletePropertyDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isDeleteSuccess = true;
    },
    [deletePropertyDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isDeleteSuccess = false;
    },
    [addProperty.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addProperty.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [addProperty.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default propertySlice.reducer;
