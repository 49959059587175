/* eslint-disable no-unreachable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllAssetData,
  getAssetData,
  createAsset,
  editAsset,
  deleteAsset,
} from "../services/assetServices";
import { getAllAssetNameService } from "../services/preventativeService";

const initialState = {
  isLoading: false,
  isSuccess: false,
  assetListingData: [],
  assetData: [],
  allAssetName: {},
};

export const fetchAllAssetName = createAsyncThunk("assetName", async (data) => {
  try {
    const resp = await getAllAssetNameService(data);
    return resp.data.data;
  } catch (error) {}
});

export const fetchAssetListing = createAsyncThunk(
  "assetListing",
  async (data) => {
    try {
      const resp = await getAllAssetData(data);
      return resp.data.data;
    } catch (error) {}
  }
);

export const fetchAssetData = createAsyncThunk("assetData", async (assetId) => {
  try {
    const resp = await getAssetData(assetId);
    return resp.data.data;
  } catch (error) {}
});

export const addAsset = createAsyncThunk("/asset/create", async (data) => {
  try {
    const { payload, navigate } = data;
    const resp = await createAsset(payload);
    if (resp.data.status === "fail") {
      toast.error(resp.data.msg);
    } else {
      toast.success("Asset create successfully");
      navigate("/asset");
    }
  } catch (error) {}
});

export const editAssetSlice = createAsyncThunk(
  "/asset/edit",
  async (payload) => {
    const { data, assetId } = payload;
    try {
      const resp = await editAsset({ data, assetId });
      if (resp.data.status === "fail") {
        toast.error(resp.data.msg);
      } else {
        toast.success(resp?.data?.data);
        // navigate("/asset");
      }
    } catch (error) {}
  }
);

export const deleteAssetSlice = createAsyncThunk(
  "deleteAsset",
  async (payload) => {
    const { assetId, navigate } = payload;
    try {
      const resp = await deleteAsset(assetId);
      if (resp.data.status === "success") {
        toast.success(resp.data.data);
        navigate("/asset");
      } else {
        toast.error(resp.data.msg);
      }
    } catch (error) {}
  }
);

const AssetSlice = createSlice({
  name: "Asset",
  initialState,
  reducers: {},

  extraReducers: {
    [fetchAllAssetName.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchAllAssetName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.allAssetName = action.payload;
    },
    [fetchAllAssetName.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [fetchAssetListing.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchAssetListing.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.assetListingData = action.payload;
    },
    [fetchAssetListing.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [fetchAssetData.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [fetchAssetData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.assetData = action.payload;
    },
    [fetchAssetData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [addAsset.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addAsset.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [addAsset.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [editAssetSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editAssetSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editAssetSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [deleteAssetSlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteAssetSlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [deleteAssetSlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default AssetSlice.reducer;
