import moment from "moment";
import { BASE_URL, axiosClient } from "./axiosClient";

export const citiesAPI = async () => {
  const resp = await axiosClient.get(`${BASE_URL}/cities`);
  return resp;
};

export const localitiesAPI = async (id) => {
  const resp = await axiosClient.get(`${BASE_URL}/localities?cityId=${id}`);
  return resp;
};

export const buildingsAPI = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/buildings`, data);
  return resp;
};
export const flatAPI = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/flats`, data);
  return resp;
};

export const createNewOwnerAPI = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/flats`, data);
  return resp;
};

export const AllVisitorsAPI = async (params) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/visitings/logs?page=${params.page}&limit=${params.limit}`
  );
  return resp;
};

export const AllStaffsAPI = async (
  currentPage,
  postPerpage,
  search,
  buildingId
) => {
  if (search.length > 0) {
    const resp = await axiosClient.get(
      `${BASE_URL}/staffs?page=${currentPage}&limit=${postPerpage}&search=${search}`
    );
    return resp;
  } else {
    const resp = await axiosClient.get(
      `${BASE_URL}/staffs?page=${currentPage}&limit=${postPerpage}`
    );
    return resp;
  }
};

export const exportAllStaffsData = async (buildingId) => {
  const resp = await axiosClient.get(`${BASE_URL}/staffs?page=1&limit=1000000`);
  return resp;
};

// User Api's

export const getUser = async ({ userId }) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/master-users/admin/${userId}`
  );
  return resp;
};

export const AllUserAPI = async (
  currentPage,
  postPerpage,
  search,
  buildingId
) => {
  if (search.length > 0) {
    const resp = await axiosClient.get(
      `${BASE_URL}/master-users?page=${currentPage}&limit=${postPerpage}&search=${search}&buildingId=${buildingId}`
    );
    return resp;
  } else {
    const resp = await axiosClient.get(
      `${BASE_URL}/master-users?page=${currentPage}&limit=${postPerpage}&buildingId=${buildingId}`
    );
    return resp;
  }
};
export const exportAllUsersData = async (buildingId="") => {
  const resp = await axiosClient.get(
    `${BASE_URL}/master-users/export?buildingId=${buildingId}`
  );
  return resp;
};

export const addUser = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/master-users`, data);
  return resp;
};

export const updateUser = async (data) => {
  const resp = await axiosClient.patch(`/master-users`, data);
  return resp;
};

// Unit Api's
export const AllUnitAPI = async (
  currentPage,
  postPerpage,
  search,
  buildingId
) => {
  if (search?.length > 0) {
    const resp = await axiosClient.get(
      `${BASE_URL}/flats/admin?page=${currentPage}&limit=${postPerpage}&search=${search}&buildingId=${buildingId}`
    );
    return resp;
  } else {
    const resp = await axiosClient.get(
      `${BASE_URL}/flats/admin?page=${currentPage}&limit=${postPerpage}&buildingId=${buildingId}`
    );
    return resp;
  }
};
export const getStaffdetail = async ({ userId, buildingId }) => {
  const resp = await axiosClient.get(`/staffs/${userId}`);
  return resp;
};
export const getAllproperties = async (currentPage, postPerpage, search) => {
  if (search.length > 0) {
    const resp = axiosClient.get(
      `/buildings/admin?page=${currentPage}&limit=${postPerpage}&search=${search}`
    );
    return resp;
  } else {
    const resp = axiosClient.get(
      `/buildings/admin?page=${currentPage}&limit=${postPerpage}`
    );
    return resp;
  }
};

export const addUnit = async (data) => {
  const resp = await axiosClient.post(`${BASE_URL}/flats`, data);
  return resp;
};

// UserMapping Api's
export const UserMappingAPI = async (params) => {
  const resp = await axiosClient.get(
    `${BASE_URL}/users/requested?page=${params.page}&limit=${params.limit}&search=${params.searchText}&buildingId=${params.buildingId}`
  );
  return resp;
};
export const DenyUserAPI = async (id) => {
  const resp = await axiosClient.delete(`${BASE_URL}/master-users/deny/${id}`);
  return resp;
};
export const ApproveUserAPI = async (data1) => {
  const resp = await axiosClient.post(
    `${BASE_URL}/master-users/approve`,
    data1
  );
  return resp;
};

export const uploadFile = async (data) => {
  const resp = await axiosClient.post("/files/upload-file", data);
  return resp;
};

export const exportFile = async (docFormData, buildingId) => {
  
  const resp = await axiosClient.post(
    `/flats/csv?buildingId=${buildingId}`,
    docFormData
  );
  return resp;
};

export const getUnit = async (params) => {
  const resp = await axiosClient.get(
    `/flats/admin/${params.userId}?buildingId=${params.buildingId}`
  );
  return resp;
};

export const getBuildingData = async (Id) => {
  const resp = await axiosClient.get(`/buildings/admin/${Id}`);
  return resp;
};

export const updateUnit = async (data) => {
  const resp = await axiosClient.patch(`/flats`, data);
  return resp;
};
export const getApartment = async () => {
  const resp = await axiosClient.get(`/admins/details`);
  return resp;
};

export const getStaffDepartment = async () => {
  const resp = await axiosClient.get(`staffs/departments`);
  return resp;
};
export const getStaffAppointment = async () => {
  const resp = await axiosClient.get(`/staffs/appointments`);
  return resp;
};
export const getStaffDesignation = async () => {
  const resp = await axiosClient.get(`/staffs/designations`);
  return resp;
};
export const addStaff = async ({ userFullData, buildingId }) => {
  const resp = await axiosClient.post(`/staffs`, userFullData);
  return resp;
};

export const nit = async (data) => {
  const resp = await axiosClient.get(`/staffs/${data}`);
  return resp;
};

export const updateStaff = async (request) => {
  const resp = await axiosClient.patch(`/staffs`, request);
  return resp;
};

export const createAnnouncement = async (data) => {
  const resp = await axiosClient.post(`/notices/create`, data);
  return resp;
};

export const getAllBuilding = async () => {
  const resp = await axiosClient.get(
    `/buildings/admin?page=1&limit=1000000000000`
  );
  return resp;
};

export const postAddNewBuilding = async (params) => {
  const resp = await axiosClient.post(`/buildings`, params);
  return resp;
};

export const getLocality = async (countryName) => {
  const resp = await axiosClient.get(`/localities?country=${countryName}`);
  return resp;
};

export const patchAddNewBuilding = async (data) => {
  const resp = await axiosClient.patch(`/buildings`, data);
  return resp;
};

export const getResidencedata = async (buildingId) => {
  const resp = await axiosClient.get(`/master-users/residents`);
  return resp;
};

export const addResidenceUser = async ({ residencePropertyfield, idinfo }) => {
  let data = {
    contractStartDate: moment(residencePropertyfield.contractStartDate).format(
      "DD-MM-YYYY"
    ),
    contractEndDate: moment(residencePropertyfield.contractEndDate).format(
      "DD-MM-YYYY"
    ),
    moveInDate: moment(residencePropertyfield.moveInDate).format("DD-MM-YYYY"),
    moveOutDate: residencePropertyfield.moveOutDate
      ? moment(residencePropertyfield.moveOutDate).format("DD-MM-YYYY")
      : null,
    flatId: idinfo.userId,
    masterUserId: idinfo.residenceName,
  };

  const resp = await axiosClient.post(`/flat-contracts`, data);
  return resp;
};

export const updateMappingUser = ({
  residencePropertyfield,
  flatContractId,
}) => {
  let data = {
    moveInDate: moment(residencePropertyfield.moveInDate).format("DD-MM-YYYY"),
    moveOutDate: residencePropertyfield.moveOutDate
      ? moment(residencePropertyfield.moveOutDate).format("DD-MM-YYYY")
      : null,
    flatContractId: flatContractId.flatContractId,
  };
  const resp = axiosClient.patch(`/flat-contracts`, data);
  return resp;
};

export const DeleteUsermappingRequest = (id) => {
  const resp = axiosClient.delete(`/flat-contracts/${id}`);
  return resp;
};

export const getCalendarData = ({ userId, date }) => {
  const res = axiosClient.get(`/staffs/calender/${userId}?date=${date}`);
  return res;
};

export const updateTimeSlot = ({ userId, payload }) => {
  const res = axiosClient.patch(`staffs/slots/${userId}`, payload);
  return res;
};

export const getOwnerDataDropDownList = () => {
  const resp = axiosClient.get(`master-users/all`);
  return resp;
};

export const getDeleteFlat = (flatId) => {
  const resp = axiosClient.delete(`/flats/${flatId}`);
  return resp;
};
export const getDeleteUser = (masterUserId) => {
  const resp = axiosClient.delete(`/master-users/admin/${masterUserId}`);
  return resp;
};

export const getSoftDeleteProperty = (buildingForDelete) => {
  const resp = axiosClient.delete(`/buildings/admin/${buildingForDelete}`);
  return resp;
};

export const getSoftDeleteStaff = (staffId) => {
  const resp = axiosClient.delete(`/staffs/${staffId}`);
  return resp;
};

export const propertyOverview=()=>{
  const resp = axiosClient.get(`/buildings/admin/statistics`)
  return resp;
}