import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider, BuildingProvider } from "./login/AuthProvider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "@tremor/react/dist/esm/tremor.css";
import { Provider } from "react-redux";
import store from "./Redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <AuthProvider>
      <BuildingProvider>
        <App />
      </BuildingProvider>
    </AuthProvider>
    </Provider>
  </React.StrictMode> 
  
);

reportWebVitals();
