import { async } from "q";
import { axiosClient } from "./axiosClient";

export const createNoticeApi = async ({ payload, buildingId }) => {
  const resp = await axiosClient.post(
    `/notices/create?buildingId=${buildingId}`,
    payload
  );
  return resp;
};

export const fetchNoticeData = async (data) => {
  const resp = await axiosClient.get(
    `/notices/admin?page=${data.page}&limit=${data.postPerpage}&title=${data.searchText}&buildingId=${data.selectedBuilding}`
  );
  return resp;
};

export const noticeDataService = async (id) => {
  const resp = await axiosClient.get(`/notices/${id}`);
  return resp;
};
export const noticeEditService = async (data) => {
  const resp = await axiosClient.patch(
    `/notices/${data?.noticeId}`,
    data.payload
  );
  return resp;
};
